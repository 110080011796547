export const table4 = {
	title: "Hauptstrasse 11",
	alignInfo: [
		"center",
		"left",
		"right",
		"right",
		"right",
		"right",
		"right",
		"right",
		"right",
		"center",
	],
	data: [
		{
			content: [
				"Wohnung",
				"Geschoss/Lage",
				"Zimmer",
				"m²",
				"Sitzplatz",
				"Loggia",
				"Keller",
				"Nettomietzins",
				"Nebenkosten",
				"Status",
			],
			link: "",
		},
		{
			content: [
				"1",
				"EG/Nord",
				"2.5",
				"57",
				"",
				"",
				"4",
				"1'150.00",
				"110.00",
				"vermietet",
			],
			link: "11_EG_150.pdf",
		},
		{
			content: [
				"2",
				"EG/Gewerberaum",
				"",
				"69",
				"",
				"",
				"7",
				"1'000.00",
				"150.00",
				"vermietet",
			],
			link: "11_EG_150.pdf",
		},
		{
			content: [
				"3",
				"EG/Riegel",
				"3.5",
				"79",
				"",
				"",
				"4",
				"1'550.00",
				"160.00",
				"vermietet",
			],
			link: "11_EG_150.pdf",
		},
		{
			content: [
				"4",
				"1. OG/Nord",
				"2.5",
				"57",
				"",
				"",
				"4",
				"1'100.00",
				"110.00",
				"vermietet",
			],
			link: "11_OG_150.pdf",
		},
		{
			content: [
				"5",
				"1. OG/Süd",
				"2.5",
				"58",
				"",
				"10",
				"4",
				"1'150.00",
				"110.00",
				"vermietet",
			],
			link: "11_OG_150.pdf",
		},
		{
			content: [
				"6",
				"1. OG/Riegel",
				"3.5",
				"79",
				"10",
				"",
				"4",
				"1'500.00",
				"160.00",
				"vermietet",
			],
			link: "11_OG_150.pdf",
		},
		{
			content: [
				"7",
				"DG/West",
				"4.5",
				"117",
				"",
				"9",
				"4",
				"2'000.00",
				"230.00",
				"vermietet",
			],
			link: "11_DG_150.pdf",
		},
		{
			content: [
				"8",
				"DG/Riegel",
				"2.5",
				"79",
				"",
				"",
				"4",
				"1'350.00",
				"160.00",
				"vermietet",
			],
			link: "11_DG_150.pdf",
		},
	],
};
