import Footer from './components/Footer';
import ThanksView from './views/ThanksView';
import Navigation from './components/Navigation';
import Rent from './views/Rent';
import Buy from './views/Buy';
import Welcome from './views/Welcome';
import Images from './views/Images';
import Contact from './views/Contact';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ErrorView from './views/ErrorView';

function App() {
	return (
		<Router>
			<div id="page-container">
				<div id="content-wrap">
					<Navigation />
					<Routes>
						<Route path="/" element={<Welcome />} />
						<Route path="/rent" element={<Rent />} />
						<Route path="/buy" element={<Buy />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/thankyou" element={<ThanksView />} />
						<Route path="/images" element={<Images />} />
						<Route element={<ErrorView />} />
					</Routes>
					<Footer />
				</div>
			</div>
		</Router>
	);
}

export default App;
