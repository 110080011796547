import Observer from "../observerstore/Observer";
import React from "react";
import Table from "react-bootstrap/Table";

interface Props {
	input: any;
}
interface State {}

export default class TableAdvanced extends Observer<Props, State> {
	click(input: string) {
		if (input !== "") {
			input = "https://sunnedoerfli-thundorf.ch/download/" + input;
			window.open(input, "_blank");
		} else {
			console.log("Kein Link");
		}
	}

	render() {
		return (
			<React.Fragment>
				<h3>{this.props.input.title}</h3>
				<Table responsive striped bordered hover size={"sm"}>
					<thead>
						<tr>
							{this.props.input.data[0].content.map(
								(p: any, i: number) => (
									<th key={i} style={{ textAlign: "left" }}>
										{p}
									</th>
								)
							)}
						</tr>
					</thead>
					<tbody>
						{this.props.input.data
							.slice(1)
							.map((p: any, i: number) => {
								let color: string = "";
								if (p.content[p.content.length -1] === "reserviert" || p.content[p.content.length -1] === "vermietet") {
									color = "grey";
								}
								return (
									<tr
										key={i}
										onClick={() => this.click(p.link)}
									>
										{p.content.map((b: any, z: number) => (
											<td
												key={z}
												style={{
													textAlign:
														this.props.input
															.alignInfo[z],
													color: color,
												}}
											>
												{b}
											</td>
										))}
									</tr>
								);
							})}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}
}
