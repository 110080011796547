import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

export default class Navigation extends React.Component {
	constructor(props: any) {
		super(props);
		this.state = {
			auktionen: [
				{
					auk_id: 0,
					auk_name: "Keine Auktionen",
				},
			],
			value: "",
		};
	}

	render() {
		return (
			<>
				<Navbar fixed="top" expand="md" variant="light" bg="light">
					<Container>
						<Navbar.Brand href="#">
							Sunnedörfli Thundorf
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse className="justify-content-end">
							<Nav className="ml-auto">
								<Nav.Link href="#/">Willkommen</Nav.Link>
								<Nav.Link href="#/rent">Miete</Nav.Link>
								<Nav.Link href="#/buy">Verkauf</Nav.Link>
								<Nav.Link href="#/contact">Kontakt</Nav.Link>
								<Nav.Link href="#/images">Bilder</Nav.Link>
								<Nav.Link
									target="_blank"
									href="https://www.sunnedoerfli-thundorf.ch/download/broschuere_thundorf.pdf"
									style={{ color: "#9a8446" }}
								>
									Exposé
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</>
		);
	}
}
