import Observer from "../observerstore/Observer";
import Carousel from "react-bootstrap/Carousel";
import React from "react";

import slideshow1 from "../img/slideshow_2/6239_Bhend_Thundorf_Aussen_K10_180321.jpg";
import slideshow2 from "../img/slideshow_2/6239_Bhend_Thundorf_Aussen_K12_180321.jpg";
import slideshow3 from "../img/slideshow_2/6239_Bhend_Thundorf_Innen_DG_K01_160321.jpg";
import slideshow4 from "../img/slideshow_2/6239_Bhend_Thundorf_Innen_DG_K03_160321.jpg";
import slideshow5 from "../img/slideshow_2/6239_Bhend_Thundorf_Innen_EG_K01_160321.jpg";
import slideshow6 from "../img/slideshow_2/6239_Bhend_Thundorf_Innen_EG_K03_160321.jpg";

interface Props {}
interface State {}

export default class Slideshow extends Observer<Props, State> {
	pictures: any[] = [
		{
			picturePath: slideshow1,
			title: "",
			text: "",
		},
		{
			picturePath: slideshow2,
			text: "",
			title: "",
		},
		{
			picturePath: slideshow3,
			title: "",
			text: "",
		},
		{
			picturePath: slideshow4,
			title: "",
			text: "",
		},
		{
			picturePath: slideshow5,
			title: "",
			text: "",
		},
		{
			picturePath: slideshow6,
			title: "",
			text: "",
		},
	];

	render() {
		return (
			<>
				<Carousel
					style={{
						marginTop: "100px",
						marginBottom: "30px",
					}}
					pause={"hover"}
				>
					{this.pictures.map((p, i) => (
						<Carousel.Item key={i}>
							<div
								style={{
									backgroundPosition: "center",
									height: "400px",
									backgroundImage:
										"url(" + p.picturePath + ")",
									backgroundSize: "cover",
								}}
							></div>
							<Carousel.Caption>
								<h3>{p.title}</h3>
								<p>{p.text}</p>
							</Carousel.Caption>
						</Carousel.Item>
					))}
				</Carousel>
			</>
		);
	}
}
