import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

interface Props {}
interface State {}

export default class Footer extends React.Component<Props, State> {
	render() {
		return (
			<React.Fragment>
				<div id="footer">
					<div
						className="text-center"
						style={{ backgroundColor: "#444" }}
					></div>
					<code className="component-info">Footer</code>
					<div className="top">
						<Container>
							<Row className="text-center">
								<Col xs={12}>
									<h3>Kontakt</h3>
									<p>
										<b style={{fontWeight: 600}}>Minger Treuhand AG</b>
										<br />
										Bahnhofstrasse 28
										<br />
										8572 Berg
									</p>
									<p>
										Tel +41 71 637 70 60
									</p>
									<p>
										<a href="mailto:info@minger-treuhand.ch">
											info@minger-treuhand.ch
										</a>
										<br />
										<a href="https://www.minger-treuhand.ch">
											www.minger-treuhand.ch
										</a>
									</p>
								</Col>
							</Row>
						</Container>
					</div>
					<div
						className="text-center"
						style={{ backgroundColor: "#222" }}
					>
						<svg height={80} width={200}>
							<path
								d="M0 0 L200 0 L100 60 L0 0"
								style={{
									fill: "#444",
								}}
							/>
						</svg>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
