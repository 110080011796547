import * as React from 'react';

import slideshow4 from '../img/slideshow_2/6239_Bhend_Thundorf_Innen_DG_K03_160321.jpg';
import FullScreenGallery, {
	FullScreenGalleryImage,
} from '../components/FullScreenGallery/FullScreenGallery';
import '../css/custom.scss';

interface IProps {}

const images: FullScreenGalleryImage[] = [
	{ original: '/img/ImageGallery/Badezimmer.jpg', alt: 'Badezimmer' },
	{ original: '/img/ImageGallery/Korridor.jpg', alt: 'Korridor' },
	{ original: '/img/ImageGallery/Kueche_Essbereich.jpg', alt: 'Küche Essbereich Bild 1' },
	{ original: '/img/ImageGallery/Kueche_Essbereich1.jpg', alt: 'Küche Essbereich Bild 2' },
	{ original: '/img/ImageGallery/Kueche.jpg', alt: 'Küche' },
	{ original: '/img/ImageGallery/Wohnzimmer_Kueche.jpg', alt: 'Wohnzimmer Küche' },
	{ original: '/img/ImageGallery/Wohnzimmer.jpg', alt: 'Wohnzimmer' },
];

export const Images: React.FC<IProps> = (props: IProps) => {
	return (
		<>
			<div
				style={{
					backgroundPosition: 'center',
					height: '400px',
					backgroundImage: 'url(' + slideshow4 + ')',
					backgroundSize: 'cover',
					marginTop: '100px',
					marginBottom: '30px',
				}}
			></div>
			<main className="container">
				<h1>Gallery</h1>
				<h3
					style={{
						marginBottom: '25px',
					}}
				>
					Kernsaniertes Riegelhaus
				</h3>
				<FullScreenGallery images={images} />
			</main>
		</>
	);
};

export default Images;
