import Slideshow from "../components/Slideshow";
import Container from "react-bootstrap/Container";
import Observer from "../observerstore/Observer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { init } from "emailjs-com";
init("user_1YkWxnIKqu5sDyPZXDVFp");

interface Props {}
interface State {}

export default class Welcome extends Observer<Props, State> {
	render() {
		return (
			<>
				<Slideshow />
				<main style={{ marginBottom: "40px" }}>
					<div style={{ marginBottom: "40px" }}>
						<Container>
							<h1>Überbauung Sunnedörfli Thundorf</h1>
						</Container>

						<div className="info info-dark">
							<Container>
								<Row>
									<Col xs={12} md={6} lg={5}>
										<h2>WOHNEN MIT HOHER LEBENSQUALITÄT</h2>
										<p>
											Im Zentrum der Gemeinde Thundorf
											entsteht ein familienfreundlicher,
											wie auch altersgerechter Lebensraum
											für Jung und Alt.
										</p>

										<p>
											In der Überbauung Sunnedörfli werden
											3 Mehrfamilienhäuser als Miet- und
											Eigentumsobjekte und ein
											Einfamilienhaus realisiert. Geplant
											sind 20 hochwertige Mietwohnungen,
											sechs Eigentumswohnungen, ein
											Einfamilienhaus und ein Gewerberaum.
											Der Wohnungsmix besteht aus 2,5–5,5
											Zimmerwohnungen zwischen 57 bis 152
											m<sup>2</sup>&nbsp;und alle mit
											optimal besonnter Terrasse oder
											Gartensitzplatz und eigener
											Waschküche/Kellerabteil. Das alte
											Riegelhaus direkt an der
											Hauptstrasse wird re noviert und in
											seinem einzigartigen Charme erhalten
											bleiben.
										</p>

										<p>
											Im Riegelhaus entsteht eine 70 m
											<sup>2</sup>&nbsp;
											grosse Gewerbefläche, welche zum
											Beispiel als Café gemietet werden
											kann.
										</p>
									</Col>
									<Col xs={6} md={6} lg={7}>
										<div className="picture-one" />
									</Col>
								</Row>
							</Container>
						</div>

						<div className="info">
							<Container>
								<Row>
									<Col xs={6} md={7}>
										<div className="picture-two" />
									</Col>
									<Col xs={12} md={5}>
										<h2>NEUBAUPROJEKT SUNNEDÖRFLI</h2>

										<p>
											Mit dem Neubauprojekt Sunnedörfli in
											Thundorf entsteht Raum zum
											Wohlfühlen für Menschen, die gerne
											im Grünen leben und dabei nicht auf
											eine hervorragende Infrastruktur und
											Anbindung verzichten möchten. Die
											Nähe zur Natur bietet den Be wohnern
											nicht nur einen modernen
											Wohnkomfort, sondern auch eine hohe
											Lebensqualität.
										</p>

										<p>
											Die ersten Wohnungen werden im
											Frühjahr 2022 bezugsbereit sein. Bis
											zum Frühjahr 2023 folgen
											etappenweise die weiteren Wohnungen.
										</p>

										<p>
											Die Überbauung Sunndedörfli ist
											zentral und gleichzeitig ruhig
											gelegen. Die nebelarme Lage
											Thundorfs macht sie zu einer sehr
											sonnigen und attraktiven Gemeinde,
											welche im Sommer die Wanderer und im
											Winter die Langläufer anlockt.
											Verschiedene Gewerbebetriebe sorgen
											für Arbeitsplätze.
										</p>
									</Col>
								</Row>
							</Container>
						</div>

						<div className="info info-dark">
							<Container>
								<Row>
									<Col xs={12} md={5}>
										<h2>HOHE LEBENSQUALITÄT …</h2>
										<p>
											Die Infrastruktur der Gemeinde ist
											mit Schule und Kindergarten, Kirchen
											in Kirchberg und Lustdorf, einem
											Gemeindesaal, Volg-Laden mit
											integrierter Poststelle, Metzgerei
											und Restaurants auf einem hohen
											Standard. Ebenso bieten zahlreiche
											Vereine einerseits die Möglichkeit
											für eine sinnvolle
											Freizeitgestaltung und sorgen
											andererseits für abwechslungsreiche
											Veranstaltungen jeglicher Art. Die
											Ge meide Thundorf ist zudem
											verkehrstechnisch gut erschlossen.
											Mit dem Postauto gelangt man innert
											einer knappen Viertelstunde zum
											Bahnhof Frauenfeld. Die
											Kantonshauptstadt mit über 19 000
											Arbeitsplätzen ist ein bedeutendes
											Zentrum für wirtschaftliche,
											politische und kulturelle
											Beziehungen. Die Autobahnanschlüsse
											Matzingen oder Frauenfeld werden in
											rund fünf Minuten erreicht.
										</p>
									</Col>
									<Col xs={6} md={7}>
										<div className="picture-three" />
									</Col>
								</Row>
							</Container>
						</div>
					</div>
				</main>
			</>
		);
	}
}
