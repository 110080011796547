import Product from "../models/Product";
import ObserverType from "./ObserverType";

interface data {
	name: string;
	nachname: string;
	product: Product;
}

class Store {
	observers: ObserverType[];
	data: data;

	constructor() {
		this.observers = [];
		this.data = {
			name: "Nathanael",
			nachname: "Erdin",
			product: new Product(0, '', 0, '')
		};
	}

	attach(observer: ObserverType): void {
		this.observers.push(observer);
	}

	detach(observer: ObserverType): void {
		for (let i = 0; i < this.observers.length; i++) {
			if (this.observers[i] === observer) {
				this.observers.splice(i, 1);
			}
		}
	}

	print(): void {
		console.log(this.data);
	}

	notify(): void {
		let i;
		for (i = 0; i < this.observers.length; i++) {
			this.observers[i].update();
		}
	}
}

export default new Store();
