import * as React from 'react';
import { useState } from 'react';
import './FullScreenGallery.scss';

export type FullScreenGalleryImage = {
	original: string;
	alt: string;
	description?: string | undefined;
};

interface IProps {
	images: FullScreenGalleryImage[];
}

export const ImageGalleryContainer2: React.FC<IProps> = (props: IProps) => {
	const [show, setShow] = useState<boolean>(false);
	const [index, setIndex] = useState<number>(0);

	const next = (e: any): void => {
		e.stopPropagation();
		let newIndex = index + 1;
		if (newIndex >= props.images.length) newIndex = 0;
		setIndex(newIndex);
	};

	const previous = (e: any): void => {
		e.stopPropagation();
		let newIndex = index - 1;
		if (newIndex < 0) newIndex = props.images.length - 1;
		setIndex(newIndex);
	};
	const open = (input: number): void => {
		setIndex(input);
		setShow(true);
	};

	const exit = (): void => {
		setShow(false);
	};

	const renderFullscreen = (): JSX.Element => {
		if (show) {
			return (
				<>
					<div id="overlay-container" onClick={exit}>
						<div onClick={previous}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="100"
								height="100"
								fill="rgba(255,255,255,0.8)"
								className="bi bi-chevron-compact-left"
								viewBox="0 0 16 16"
							>
								<path
									fill-rule="evenodd"
									d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
								/>
							</svg>
						</div>
						<div className="overlay-item-picture">
							<img
								alt={props.images[index].description}
								src={props.images[index].original}
							/>
							{props.images[index].description && (
								<div
									style={{
										padding: '10px',
										backgroundColor: 'rgba(255,255,255,0.8)',
									}}
								>
									{props.images[index].description}
								</div>
							)}
						</div>
						<div onClick={next}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="100"
								height="100"
								fill="rgba(255,255,255,0.8)"
								className="bi bi-chevron-compact-right"
								viewBox="0 0 16 16"
							>
								<path
									fill-rule="evenodd"
									d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
								/>
							</svg>
						</div>
					</div>
				</>
			);
		} else {
			return <></>;
		}
	};

	return (
		<>
			<div className="container">
				<div className="row">
					{props.images.map((e: any, i: number) => (
						<div
							className="col-6 col-sm-4 col-md-3"
							key={i}
							style={{ marginBottom: '25px' }}
						>
							<div className="input-picture-upload" style={{ height: '180px' }}>
								<img
									onClick={() => open(i)}
									src={e.original}
									alt={e.description}
									className="img-fluid"
									style={{
										height: '100%',
										width: '100%',
										objectFit: 'cover',
									}}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
			{renderFullscreen()}
		</>
	);
};

export default ImageGalleryContainer2;
