export const table1 = {
	title: 'Kirchbergstrasse 4c',
	alignInfo: [
		'center',
		'left',
		'right',
		'right',
		'right',
		'right',
		'right',
		'right',
		'center',
	],
	data: [
		{
			content: [
				'Wohnung',
				'Geschoss/Lage',
				'Zimmer',
				'm²',
				'Sitzplatz',
				'Loggia',
				'WK/Keller',
				'Preise (exkl. PP)',
				'Status',
			],
			link: '',
		},
		{
			content: [
				'1',
				'EG/West',
				'3.5',
				'100',
				'15',
				'15',
				'19',
				"–",
				'verkauft',
			],
			link: '4c_EG_150.pdf',
		},
		{
			content: [
				'2',
				'EG/Ost',
				'5.5',
				'130',
				'15',
				'16',
				'23',
				"–",
				'verkauft',
			],
			link: '4c_EG_150.pdf',
		},
		{
			content: [
				'3',
				'1. OG/West',
				'3.5',
				'100',
				'',
				'15',
				'19',
				"–",
				'verkauft',
			],
			link: '4c_OG_150.pdf',
		},
		{
			content: [
				'4',
				'1. OG/Ost',
				'5.5',
				'130',
				'',
				'16',
				'20',
				"–",
				'verkauft',
			],
			link: '4c_OG_150.pdf',
		},
		{
			content: ['5', 'DG/West', '4.5', '144', '', '16', '21', "882'000.00", ''],
			link: '4c_DG_150.pdf',
		},
		{
			content: [
				'6',
				'DG/Ost',
				'5.5',
				'152',
				'',
				'15',
				'24',
				"–",
				'verkauft',
			],
			link: '4c_DG_150.pdf',
		},
	],
}

// link: "https://applescriptlibrary.files.wordpress.com/2013/11/indesign_scriptingguide_as.pdf",
