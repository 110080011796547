import React from "react";

export default class Error extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h2>404</h2>
			</React.Fragment>
		);
	}
}
