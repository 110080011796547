import React from "react";
import emailjs from "emailjs-com";
import Observer from "../observerstore/Observer";
import Container from "react-bootstrap/Container";
import { init } from "emailjs-com";
init("user_1YkWxnIKqu5sDyPZXDVFp");

interface Props {}
interface State {}

export default class ThanksView extends Observer<Props, State> {
	sendEmail(e: any) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_p5djrrj",
				"template_asmmte9",
				e.target,
				"user_1YkWxnIKqu5sDyPZXDVFp"
			)
			.then(
				(result) => {
					console.log(result.text);
					window.location.href = "#/thankyou";
				},
				(error) => {
					console.log(error.text);
					window.location.href = "#/error";
				}
			);
	}

	render() {
		return (
			<>
				<div style={{ marginTop: "135px" }}>
					<Container>
						<h1>Vielen Dank.</h1>
						<hr />
						Wir haben Ihnen eine Bestätigungs-E-Mail gesendet.
						<br />
						<br />
						Besuchen Sie uns doch unter{" "}
						<a
							href="https://www.minger-treuhand.ch/home.html"
							target="_blank"
							rel="noreferrer"
						>
							www.minger-treuhand.ch
						</a>
					</Container>
				</div>
			</>
		);
	}
}
