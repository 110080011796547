import Maker from "./Maker";

export default class Product {
	id: number;
	name: string;
	maker: Maker;

	constructor(id: number, name: string, makerid: number, makername: string) {
		this.id = id;
		this.name = name;
		this.maker = new Maker(makerid, makername);
	}

	get() {
		return {
			id: this.id,
			name: this.name
		};
	}

	createAPI(): void {
		console.log("Produkt via API erstellen");
	}

	readAPI(Store: any): void {
		console.log("Produkt via API zurückgeben");
		this.id = 213;
		this.name = "Hammer"
		this.maker = new Maker(23, 'Stiehl')
		Store.notify();
	}

	updateAPI(): void {
		console.log("Produkt via API aktualisieren");
	}

	deleteAPI(): void {
		console.log("Produkt via API löschen");
	}
}
